import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/auth/AuthLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import Login from "@/pages/auth/Login.vue";
import UsersPage from "@/pages/users/UsersPage.vue";
import StandsPage from "../pages/stands/StandsPage.vue";
import StocksPage from "../pages/stocks/StocksPage.vue";
import CategoriesPage from "../pages/categories/CategoriesPage.vue";
import TagsPage from "../pages/tags/TagsPage.vue";
import ProduitsPage from "../pages/produits/ProduitsPage.vue";
import ProduitDetails from "../pages/produits/ProduitDetails.vue";
import OrdersPosPage from "../pages/orders/POS/OrdersPosPage.vue";
import OrdersEcomPage from '../pages/orders/ECOM/OrdersEcomPage.vue';
import CouponsPage from '../pages/coupons/CouponsPage.vue';
import RapportPage from "../pages/rapport/RapportPage.vue";
import GlobalStatsPage from "../pages/stats/globalStatsPage.vue";
import UserStatsPage from "../pages/stats/userStatsPage.vue";
import StatsPage from "../pages/stats/statsPage.vue";
import MailingsPage from "../pages/mailings/MailingsPage.vue";
import MenusPage from "../pages/settings/menus/MenusPage.vue"
import InfosPage from "../pages/settings/infos/InfosPage.vue"
import BlogsPage from "../pages/blogs/BlogsPage.vue"
const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { roles: [3, 4] },
      },
      {
        path: "user",
        name: "User Profile",
        component: UserProfile,
        meta: { roles: [3, 4] },
      },
      {
        path: "users",
        name: "Users",
        component: UsersPage,
        meta: { roles: [3, 4] },
      },
      {
        path: "stands",
        name: "Stands",
        component: StandsPage,
        meta: { roles: [3, 4] },
      },
      {
        path: "stocks",
        name: "Stocks",
        component: StocksPage,
        meta: { roles: [3, 4] },
      },
      {
        path: "categories",
        name: "Categories",
        component: CategoriesPage,
        meta: { roles: [3, 4] },
      },
      {
        path: "tags",
        name: "Tags",
        component: TagsPage,
        meta: { roles: [3, 4] },
      },
      {
        path: "products",
        name: "Products",
        component: ProduitsPage,
        meta: { roles: [3, 4] },
      },
      {
        name: 'products-details', // Nom de la route
        path: '/products/:id', // Chemin avec paramètre d'ID du produit
        component: ProduitDetails, // Composante pour afficher les détails du produit
        props: true, // Passez les paramètres en tant que props à la composante
        meta: { roles: [3, 4] },
      },
      {
        name: 'orders', // Nom de la route
        path: '/orders', // Chemin avec paramètre d'ID du produit
        component: OrdersPosPage, // Composante pour afficher les détails du produit
        meta: { roles: [2,3, 4] },
      },
      {
        name: 'orders-ecom', // Nom de la route
        path: '/orders/ecom', // Chemin avec paramètre d'ID du produit
        component: OrdersEcomPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      {
        name: 'coupons', // Nom de la route
        path: '/coupons', // Chemin avec paramètre d'ID du produit
        component: CouponsPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      {
        name: 'rapports', // Nom de la route
        path: '/rapports', // Chemin avec paramètre d'ID du produit
        component: RapportPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      {
        name: 'stats', // Nom de la route
        path: '/stats', // Chemin avec paramètre d'ID du produit
        component: StatsPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      {
        name: 'mailings', // Nom de la route
        path: '/mailings', // Chemin avec paramètre d'ID du produit
        component: MailingsPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      {
        name: 'menus', // Nom de la route
        path: '/settings/menus', // Chemin avec paramètre d'ID du produit
        component: MenusPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      
      {
        name: 'Infos Store', // Nom de la route
        path: '/settings/infos', // Chemin avec paramètre d'ID du produit
        component: InfosPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
      {
        name: 'Blog', // Nom de la route
        path: '/blog/posts', // Chemin avec paramètre d'ID du produit
        component: BlogsPage, // Composante pour afficher les détails du produit
        meta: { roles: [3, 4] },
      },
    ]
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      }
    ]
  },
  {
    path: "*",
    redirect: "/dashboard"
  }
];

export default routes;
